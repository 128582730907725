import * as React from "react";
import styled from "styled-components";
import { Button, Fab, Link } from "gatsby-theme-material-ui";
import { Container, Box, Typography, AppBar, Toolbar } from "@material-ui/core";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import GithubMark from "../../assets/github_mark_white.svg";

const StyledToolbar = styled(Toolbar)`
  min-height: 54px;
`;

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 5rem;
  right: 5rem;
`;

function scrollToPageTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

// eslint-disable-next-line react/prop-types
export default function Layout({ children }) {
  const { title } = useSiteMetadata();

  return (
    <>
      <AppBar variant="outlined" position="sticky">
        <StyledToolbar>
          <Typography variant="h6">{title}</Typography>
          <Button to="/" color="inherit" style={{ marginLeft: "10px" }}>
            首页
          </Button>
          <Button to="/about" color="inherit">
            关于
          </Button>
        </StyledToolbar>
      </AppBar>
      <Container
        maxWidth="md"
        style={{
          // 即使页面上内容较少，也要把底部条放到viewport底部，不然会有喘不过气的感觉
          minHeight: "calc(100vh - 156px)",
          // 避免底部条和内容的末尾挨得太近，导致产生“内容是否被截断了”的疑惑
          paddingBottom: "20px",
          wordBreak: "break-all",
        }}
      >
        <Box my={4}>
          <main>{children}</main>
        </Box>
      </Container>
      <Box display="flex" justifyContent="center" p={3} bgcolor="primary.main">
        <Box>
          <GithubMark
            style={{ width: "1em", height: "1em", marginTop: "4px" }}
          />
        </Box>
        <Box ml={0.5}>
          <Link
            to="https://github.com/CoolSpring8"
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            @CoolSpring8
          </Link>
        </Box>
      </Box>
      <StyledFab onClick={scrollToPageTop}>回到顶部</StyledFab>
    </>
  );
}
