import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function Seo({ lang, title, canonical }) {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: "cmn-Hans",
  title: "CoolSpring's Blog",
  canonical: null,
};

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  canonical: PropTypes.string,
};

export default Seo;
